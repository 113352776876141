import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import SVG from 'react-inlinesvg';
import logo from '@/assets/svg/logo_new.svg';
import logoutIcon from '@/assets/svg/logout-circle.svg';
import appStoreImg from '@/assets/svg/app-store.svg';
import googlePlayImg from '@/assets/svg/google-play.svg';

import userThunks from '@/store/thunks/user';
import { authActions, authSelector } from '@/store/reducers/auth';
import { useDrawer } from '@/context/DrawerContext';
import UserUtils from '@/utils/user';
import { useMemo } from 'react';
import UseCurrentRoute from '@/hooks/UseCurrentRoute';
import { ROUTE_NAMES } from '@/router';
import { appSettingsActions } from '@/store/reducers/settings';
import commonUtils from '@/utils/common';
import GettingStartedInstructions from '../GettingStartedInstructions/GettingStartedInstructions';
import UseScreenSize from '@/hooks/UseScreenSize';
import { teacherActions } from '@/store/reducers/teacher';

type MenuItem = {
  id: string;
  icon: string;
  label: string;
  path?: string;
  active?: boolean;
};

const MobileDrawer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const drawer = useDrawer();
  const { user } = useAppSelector(authSelector);
  const currentRoute = UseCurrentRoute();
  const { isMobile } = UseScreenSize();

  const isTeacher = useMemo(() => UserUtils.isTeacher(user), [user]);
  const isStudent = useMemo(() => UserUtils.isStudent(user), [user]);

  const menuItems = useMemo<MenuItem[]>(() => {
    if (isTeacher) {
      return [
        {
          id: 'my_students',
          icon: 'students',
          label: 'settings.my_students',
          path: '/dashboard/my-students',
          active: currentRoute === ROUTE_NAMES.MY_STUDENTS,
        },
        {
          id: 'home',
          icon: 'book',
          label: 'settings.my_games',
          path: '/dashboard/games',
          active: currentRoute === ROUTE_NAMES.GAMES,
        },
        {
          id: 'tutor',
          icon: 'tutor',
          label: 'settings.my_tutor',
          path: '/dashboard/tutor',
          active: currentRoute === ROUTE_NAMES.TUTOR,
        },
        { id: 'settings', icon: 'settings', label: 'settings.title' },
      ];
    }

    return [
      {
        id: 'home',
        icon: 'home',
        label: 'settings.home',
        path: '/dashboard/games',
        active: currentRoute === ROUTE_NAMES.GAMES,
      },
      {
        id: 'tutor',
        icon: 'tutor',
        label: 'settings.my_tutor',
        path: '/dashboard/tutor',
        active: currentRoute === ROUTE_NAMES.TUTOR,
      },
      {
        id: 'profile',
        icon: 'profile',
        label: 'settings.profile',
        path: '/dashboard/profile',
        active: currentRoute === ROUTE_NAMES.PROFILE,
      },

      { id: 'settings', icon: 'settings', label: 'settings.title' },
    ];
  }, [isTeacher, currentRoute]);

  const logOut = () => {
    localStorage.removeItem('userEmail');
    dispatch(userThunks.signOut());
    dispatch(authActions.signOut());
    dispatch(appSettingsActions.clearAppSettings());
    dispatch(teacherActions.setStudents([]));

    i18n.changeLanguage('en');
  };

  const onTapMenuItem = (item: MenuItem) => {
    if (item.path) {
      navigate(item.path);
      drawer.closeMobileDrawer();
      return;
    }

    switch (item.id) {
      case 'settings': {
        drawer.closeMobileDrawer();
        drawer.openDrawer();
      }
    }
  };

  const isIOS = commonUtils.isIOS();

  const onGoToStore = () => {
    window.location.href = isIOS
      ? 'itms-apps://apps.apple.com/us/app/britannica-english/6472614240'
      : 'market://details?id=com.melingo.app';
  };

  return (
    <div className="mobile-drawer-wrapper">
      <div className="mobile-drawer-head">
        <SVG src={logo} />

        <div className="mobile-drawer-close">
          <button onClick={() => drawer.closeMobileDrawer()}></button>
        </div>
      </div>

      <ul className="mobile-drawer-menu">
        {menuItems.map((item, key) => {
          return (
            <li key={key} className="mobile-drawer-menu-item">
              <a
                className={cls(item.icon, 'menu-item', { active: item.active })}
                onClick={() => onTapMenuItem(item)}
              >
                {t(item.label)}
              </a>
            </li>
          );
        })}
      </ul>

      <div className="mobile-drawer-bottom">
        {isMobile && isTeacher && <GettingStartedInstructions />}
        {isStudent && (
          <button onClick={onGoToStore}>
            <SVG src={isIOS ? appStoreImg : googlePlayImg} />
          </button>
        )}
        <a onClick={logOut}>
          <SVG src={logoutIcon} />
          {t('logout')}
        </a>
      </div>
    </div>
  );
};

export default MobileDrawer;
